import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpWarehouseEntity from "../entity";
import WarehouseWarehouseLocationsOverview from "./show/warehouse_locations";
import WarehouseWarehouseLocationPalletsOverview from "./show/warehouse_location_pallets";
import WarehouseWMSOverview from "./show/wms";
export default class ErpWarehouseShow extends DefaultController {
    async init() {
        this.entity = "erp/warehouses"
        await super.init();
        this.childs = {
            warehouse_locations: new WarehouseWarehouseLocationsOverview(this),
            warehouse_location_pallets: new WarehouseWarehouseLocationPalletsOverview(this),
            wms: new WarehouseWMSOverview(this),
        }
    }

    async updateEntity(data: any) {
        jQuery("#warehouse_visibilities").val("").trigger("change");
        data.visibilities.forEach((visibility: any) => {
            const option = new Option(visibility.user.name, visibility.user.uuid, true, true);
            jQuery("#warehouse_visibilities").append(option).trigger('change');
        })
    }
    protected getEntityData(elem: any) {
        return ErpWarehouseEntity.getEntityData(elem)
    }
    bindListeners() {

        jQuery("#warehouse_visibilities").on("select2:select", async (e: any) => {
            const data = e.params.data.data
            await Utils.entity.upsert({
                user_id: data.uuid,
                warehouse_id: this.data.id,
                mode: "add"
            }, 'erp/warehouse_visibilities')
        });
        jQuery("#warehouse_visibilities").on("select2:unselect", async (e: any) => {
            const data = e.params.data
            await Utils.entity.upsert({
                user_id: data.id,
                warehouse_id: this.data.id,
                mode: "delete"
            }, 'erp/warehouse_visibilities')
        });

        (document.querySelector("#editErpWarehouseForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editErpWarehouseForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.getEntity()
                    const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editWarehouse") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('warehouse.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
    }
}